import React, { Component } from "react";
import { BrowserRouter, Route} from "react-router-dom";
import LandingPage from "./LandingPage";
import Header from "../components_2/Header";
import WorksPage from "../components_2/WorksPage";
import ProjectPage from "../components_2/ProjectPage";
import ServicePage from "./ServicePage";
import WorkshopPage from "./WorkshopPage";
import ContactsPage from "./Contacts";
import ShowreelPage from "./ShowreelPage";
import Services from "./Services";
import styled from "styled-components";
import { LargeDevice, Mobile } from "../components/ReactResponsiveComponents";
import MobileNavBar from "../components/MobileNavBar";
import mediaQueries from "../constants/mediaQueries";
import Test from "../components/LoadSitePage";
import { connect } from "react-redux";
import "react-alice-carousel/lib/alice-carousel.css";
import ScrollToTop from '../utils/ScrollToTop';
import { HeaderMargin } from "./BaseComponents";

const WebsiteContainer = styled.div`
  -webkit-overflow-scrolling: touch;
  overflow:hidden;
  background: rgb(241, 240, 236);
  @media ${mediaQueries.mobile} {
    width: 100%;
    background: rgb(241, 240, 236);
  }
  @media ${mediaQueries.mediumDevices} {
    width: calc(100vw - 10px); // scroll bar offset glitch solution
    background: rgb(241, 240, 236);
  }
`;



class App extends Component {
  componentDidMount() {}
  // !this.props.isSiteLoaded
  render() {
    return (
      <WebsiteContainer>
        {false ? (
          <Test />
        ) : (
          <BrowserRouter>
            <ScrollToTop/>
            <Mobile>
              <MobileNavBar />
            </Mobile>

            <LargeDevice>
              <Header />
              <HeaderMargin></HeaderMargin>
            </LargeDevice>

            <Route exact path="/" component={LandingPage} />


           

            <Route exact path="/projetos" component={WorksPage} />
            {/* <Route exact path="/projetos/:id" component={ProjectPage} /> */}

            <Route exact path="/servicos_producao" component={Services} />
            {/* <Route exact path="/projetos/:id" component={ProjectPage} /> */}

            <Route
              exact
              path="/projetos/:id"
              render={(props) => (
                <ProjectPage key={props.match.params.id} {...props} />
              )}
            />

            <Route
              exact
              path="/servicos/:id"
              render={(props) => (
                <ServicePage key={props.match.params.id} {...props} />
              )}
            />

            <Route
              exact
              path="/servicos/:id/:scrollSection"
              render={(props) => (
                <ServicePage key={props.match.params.id} {...props} />
              )}
            />

            <Route exact path="/about" render={(props) => (
                <WorkshopPage {...props} />
                )}
            />

            <Route exact path="/contacts" component={ContactsPage} />

            <Route exact path="/showreel" render={(props) => (
                <ShowreelPage year={props.match.params.id} url={props.match.params.type} {...props}/>
                )} 
            />

          </BrowserRouter>
        )}
      </WebsiteContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return { isSiteLoaded: state.isSiteLoaded };
};

export default connect(
  mapStateToProps,
  {}
)(App);
