export default [
  {
    title: "Cinema | Publicidade",
    sections: [
      { title: "Cenários", link: "/servicos/design-producao-cenarios" },
      { title: "Adereços", link: "/servicos/design-producao-aderecos" },
      { title: "SFX On-Set", link: "/servicos/efeitos-especiais-set" },
      { title: "Eletrónica | Interatividade", link: "/servicos/multimedia/interatividade", key: "2" }
    ],
  },
  {
    title: "Eventos | Exibições",
    sections: [
      { title: "Showrooms | Stands", link: "/servicos/showrooms-events"},
      { title: "Vitrines | Instalações", link: "/servicos/vitrines-instalacoes"}
    ],
  },
  {
    title: "Serviços",
    link: "/servicos_producao",
    sections: [],
  },
  {
    title: "Projetos",
    link: "/projetos",
    sections: [],
  },
  {
    title: "Workshop",
    sections: [
      // { title: "Oficina", link: "/workshop/oficina" },
      { title: "Sobre", link: "/about"},
      { title: "Showreels", link: "/showreel"},
      { title: "Contactos", link: "/contacts" }
    ],
  }
];
  