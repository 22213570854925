import React, {  useState } from "react";
import styled from "styled-components";
import { fadeIn } from "../animations";
import { Link } from "react-router-dom";
import mediaQueries from "../constants/mediaQueries";
import { RemoveScroll } from "react-remove-scroll";

const itemsFadeInDelay = 50; // in ms

const Container = styled.div`
  position: fixed;
  overflow: hidden;
  width: 100%;
  user-select: none;
  height: calc(100vh);
  z-index: 998;
   overflow: scroll;
  transform: ${(props) =>
    props.active ? `translateX(10%)` : `translateX(120%)`};
  transition: transform 500ms ease-in-out;
  background: #00373e;
  @media ${mediaQueries.extraSmallDevices} {
    margin-top: 0px;
  }
  @media ${mediaQueries.smallDevices} {
    margin-top: 1px;
  }
`;

const MenuContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background: #00373e;

  @media ${mediaQueries.extraSmallDevices} {
    padding-left: 20px;
    padding-top: 50px;
    padding-bottom: 100px;
  }
  @media ${mediaQueries.smallDevices} {
    padding-left: 20px;
    padding-top: 50px;
    padding-bottom: 100px;
  }
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0;
  text-align: left;
  font-weight: bold;
  font-family: "Druk", sans-serif;
  color: white;
  padding: 5px;
  font-weight: 200;
  animation-name: ${fadeIn};
  animation-duration: 500ms;
  animation-delay: ${(props) => props.fadeInDelay};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 13px;
    margin-top: 25px;
    width: 100%;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 16px;
    margin-top: 40px;
    width: 100%;
  }
  &:hover {
    text-decoration: none;
    /* background: black;
    color: white; */
  }
`;

const SubMenuItem = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0;
  text-align: left;
  font-family: "DM Sans", sans-serif;
  color: white;
  animation-name: ${fadeIn};
  animation-duration: 500ms;
  animation-delay: ${(props) => props.fadeInDelay};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  background: #0071BC;
  border-radius: 5px;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 13px;
    margin-top: 10px;
    padding: 5px;
    margin-left: 10px;
    padding-left: 10px;
    width: 100%;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 16px;
    margin-top: 20px;
    padding: 5px;
    padding-left: 10px;
    margin-left: 20px;
    width: 100%;
  }
  &:hover {
    text-decoration: none;
    /* background: black;
    color: white; */
  }
`;

const MenuItemTitle = styled.div``;

const BrandRegister = styled.div`
  color: grey;
  font-size: calc(3vw);
  @media ${mediaQueries.extraSmallDevices} {
    margin-top: 65px;
  }
  @media ${mediaQueries.smallDevices} {
    margin-top: 65px;
  }
`;

const DropdownItem = ({ title, subsections, key_, buttonClickHandler }) => {
  const [isActive, setActive] = useState(false);
  return (
    <MenuItem
      fadeInDelay={`${key_ * itemsFadeInDelay}ms`}
      onClick={() => (isActive ? setActive(false) : setActive(true))}
    >
      <MenuItemTitle>{title}</MenuItemTitle>
      {isActive &&
        subsections.map((subsection, i) => (
          <SubMenuItem
            key={i}
            fadeInDelay={`${i * itemsFadeInDelay}ms`}
            onClick={() => {
              setActive(false);
              buttonClickHandler();
            }}
            as={Link}
            to={subsection.link}
          >
            {subsection.title}
          </SubMenuItem>
        ))}
    </MenuItem>
  );
};

const NoDropdownItem = ({ title, link, key_, buttonClickHandler }) => {
  return (
    <MenuItem
      fadeInDelay={`${key_ * itemsFadeInDelay}ms`}
      onClick={() => buttonClickHandler()}
      as={Link}
      to={link}
    >
      <MenuItemTitle>{title}</MenuItemTitle>
    </MenuItem>
  );
};

class MobileNavigationMenu extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props.sections);
  }
  renderMenuItems = () =>
    this.props.sections.map((item, i) => {
      if (item.sections.length !== 0) {
        return (
          <DropdownItem
            key={i}
            key_={i}
            title={item.title}
            subsections={item.sections}
            buttonClickHandler={this.props.buttonClickHandler}
          />
        );
      } else {
        return (
          <NoDropdownItem
            key={i}
            key_={i}
            title={item.title}
            link={item.link}
            buttonClickHandler={this.props.buttonClickHandler}
          />
        );
      }
    });

  render() {
    return (
      <RemoveScroll enabled={this.props.active}>
        <Container active={this.props.active}>
          <MenuContainer active={this.props.active}>
            {this.renderMenuItems()}
            <BrandRegister>2025 || BURIL WORKSHOP</BrandRegister>
          </MenuContainer>
        </Container>
      </RemoveScroll>
    );
  }
}

export default MobileNavigationMenu;
