import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import mediaQueries from "../constants/mediaQueries";
import breakpoints from "../constants/breakpoints";

const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0px 0px;
  color:black;
  /* color: #00373e; */
  justify-content: flex-start;
  
  transition: transform 200ms;
  transform: scale(1.0, 1.0);
  text-decoration: none;

  &:hover {
    transition: transform 200ms;
    transform: scale(1.02, 1.02);
    text-decoration: none;
    color:rgb(19, 19, 19);
  }
  @media ${mediaQueries.extraSmallDevices} {
    margin: 20px 0px;
  }
  @media ${mediaQueries.smallDevices} {
    margin: 20px 0px;
  }
  @media ${mediaQueries.mediumDevices} {
    margin: 20px 20px;
  }
  @media ${mediaQueries.largeDevices} {
    margin: 20px 25px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    margin: 20px 40px;
    /* min-width: 350px;
    max-width: 500px; */
  }
`;

const TumbnailPadding = styled.div`
  display: flex;
  flex-direction: column; 
  width: 100%;
  @media ${mediaQueries.extraSmallDevices} {
  }
  @media ${mediaQueries.smallDevices} {
  }
  @media ${mediaQueries.mediumDevices} {
  }
  @media ${mediaQueries.largeDevices} {
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
  }
`;

const ThumbnailTitle = styled.div`
  font-family: Druk, sans-serif;
  font-size: 16px;
  margin-top: 15px;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 14px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 17px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 14px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 16px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 16px;
  }
`;

const ThumbnailSubtitle = styled.div`
  font-family: "DM Sans", sans-serif;
  font-style: italic;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 14px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 16px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 14px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 14px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 17px;
  }
`;

const ThumbnailImage = styled.div`
  width: 100%;
`;

const ProjectThumbnail = ({ title, subtitle, imageSrc, link }) => {
  const [open, setOpen] = useState(false);

  return (
    <ThumbnailContainer as={Link} to={link}>
      <ThumbnailImage>
        <img width={"100%"} src={imageSrc} />
      </ThumbnailImage>
      <TumbnailPadding>
        <ThumbnailTitle>{title}</ThumbnailTitle>
        <ThumbnailSubtitle>{subtitle}</ThumbnailSubtitle>
      </TumbnailPadding>
    </ThumbnailContainer>
  );
};

export default ProjectThumbnail;
