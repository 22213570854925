import React, { useState, useRef, useEffect } from 'react';
import styled , { keyframes } from "styled-components";
import axios from "axios";
import Cover from "./Cover";
import baseUrl from "../constants/baseUrl";
import mediaQueries from "../constants/mediaQueries";
import breakpoints from "../constants/breakpoints";
import { LargeDevice, Mobile } from "../components/ReactResponsiveComponents";

const url = baseUrl.services;

const slideDown = keyframes`
  0% {
    transform: translateY(-50px); /* Começa acima do normal */
  }
  100% {
    transform: translateY(0); /* Vai para a posição original */
  }
`;

const FadeInWrapper = styled.div`
  opacity: 0;
  animation: fadeIn 2s forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const data = [{
        serviceName: "Design 2D/3D",
        description: "A criação de visualizações 2D/3D e maquetes impactantes são o nosso ponto de partida para a construção colaborativa. Estas representações permitem-nos comunicar mais eficazmente com os diretores criativos e planear os pormenores com precisão.",
        imageURL: "https://burilworkshop.s3.eu-west-3.amazonaws.com/images/services/gerais/design.jpg"
    },{
        serviceName: "Carpintaria",
        description: "A carpintaria é a base da maioria dos nossos projetos, combinando tradição e precisão. Com uma equipa experiente e ferramentas especializadas, criamos peças sob medida. Unimos a técnica apurada e a atenção aos detalhes para resultados duradouros e de qualidade.",
        imageURL: "https://burilworkshop.s3.eu-west-3.amazonaws.com/images/services/gerais/carpintaria.jpg"
    },{
        serviceName: "Serralharia",
        description: "Dispomos de uma serralharia para projetos que exigem mais resistência. O trabalho da nossa serralharia complementa a nossa marcenaria, permitindo-nos ter soluções diferenciadoras e eficazes.",
        imageURL: "https://burilworkshop.s3.eu-west-3.amazonaws.com/images/services/gerais/serralharia.jpg"
    },{
        serviceName: "Impressão 3D",
        description: "Embora as técnicas tradicionais sejam essenciais, alguns projetos pedem a precisão e a agilidade da impressão 3D. Com um laboratório equipado com máquinas de grande e pequeno formato, conseguimos produzir peças complexas num curto espaço de tempo.",
        imageURL: "https://burilworkshop.s3.eu-west-3.amazonaws.com/images/services/gerais/impressao3d.jpg"
    },{
        serviceName: "Fresagem e Corte CNC",
        description: "A fresagem e corte CNC são, atualmente, tecnologias indispensáveis para dar resposta aos trabalhos de linhas mais complexas. Integramos essas tecnologias na nossa serralharia e carpintaria para acelerar e dar precisão à produção de peças.",
        imageURL: "https://burilworkshop.s3.eu-west-3.amazonaws.com/images/services/gerais/cnc.jpg"
    },{
        serviceName: "Escultura",
        description: "Dispomos de uma oficina de escultura para a criação de peças orgânicas e detalhadas. A escultura é essencial para dar aos projetos que realizamos, peças com alma e delicadeza.",
        imageURL: "https://burilworkshop.s3.eu-west-3.amazonaws.com/images/services/gerais/escultura.jpg"
    },
    {
        serviceName: "Impressão Digital",
        description: "Dispomos de uma área equipada com equipamentos de impressão LÁTEX para projetos que exigem a transformação de espaços e a comunicação de ideias de forma marcante e gráfica.",
        imageURL: "https://burilworkshop.s3.eu-west-3.amazonaws.com/images/services/gerais/BW_SERVICOS_07_PRINTING.jpg"
    },
    {
        serviceName: "Patines",
        description: "Dispomos do serviço de pinturas especiais e patines. Estas técnicas são essenciais para conferir caráter e a autenticidade aos espaços e aos objetos que produzimos.",
        imageURL: "https://burilworkshop.s3.eu-west-3.amazonaws.com/images/services/gerais/patines.jpg"
    }
];

const Page = styled.div`
    background: rgb(241, 240, 236);
`;

const Subheader = styled.div`
    position: fixed;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: Druk, sans-serif;
    border-right: 1px solid;
    border-color: grey;
    padding-left: 30px;
    padding-top: 50px;
    /* Aplica a animação */
  animation: ${slideDown} 500ms ease-out;
    @media ${mediaQueries.extraSmallDevices} {
      width: 26%;
        

    }
    @media ${mediaQueries.smallDevices} {
      width: 26%;
        

    }
    @media ${mediaQueries.mediumDevices} {
      width: 35%;
        

    }
    @media ${mediaQueries.largeDevices} {
      width: 26%;

    }
    @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
      width: 25%;
    }
`;

const SubheaderSectionButton = styled.button`
  font-family: Druk, sans-serif;
  text-decoration: none;
  border: none;
  background:rgb(241, 240, 236);
  margin-bottom: 15px; 
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 13px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 14px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 12px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 14px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 16px;
  }

  &:hover {
    color: black;
    background-color: rgb(241, 240, 236);
  }

  /* Efeito de click (ativo) */
  &:active {
    background-color: rgb(241, 240, 236);
    color: ${(props) => (props.isActive ? 'black' :  'rgb(200,200,200)')};
  }
  &:focus {
    background-color: rgb(241, 240, 236);
    color: ${(props) => (props.isActive ? 'black' :  'rgb(200,200,200)')};
  }
  color: ${(props) => (props.isActive ? 'black' :  'rgb(200,200,200)')};
  transition: color 0.3s ease;
`;

const Sectionsbox = styled.div`
  @media ${mediaQueries.extraSmallDevices} {
    width: 100%;
    margin-left: 0;
   
  }
  @media ${mediaQueries.smallDevices} {
    width: 100%;
    margin-left: 0;
   
  }
  @media ${mediaQueries.mediumDevices} {
    width: 65%;
    margin-left: 35%;

  }
  @media ${mediaQueries.largeDevices} {
    width: 74%;
    margin-left: 26%;

  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 75%;
    margin-left: 25%;
  }
`;

const Section = styled.div`
  color: black;
  width: 100%;
  font-family: Druk, sans-serif;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 80px;
  user-select: none;
  /* Aplica a animação */
  animation: ${slideDown} 500ms ease-out;
  @media ${mediaQueries.extraSmallDevices} {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media ${mediaQueries.smallDevices} {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media ${mediaQueries.mediumDevices} {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media ${mediaQueries.largeDevices} {
    padding-left: 30px;
    padding-right: 30px;
  }
  
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    padding-left: 30px;
    padding-right: 150px;
  }
`;

const SectionTitle = styled.div`
  font-family: "Druk", sans-serif;
  margin-top: 40px;
  padding-bottom: 40px;
  text-transform: uppercase;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 14px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 15px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 14px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 18px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 22px;
  }
`;

const Description = styled.div`
  font-family: "DM Sans", sans-serif;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 70px;
  padding-bottom: 0px;
  text-align: justify;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 15px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 17px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 14px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 18px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 22px;
  }
`;

const ImageWrapper = styled.div`
width: 100%;
@media ${mediaQueries.extraSmallDevices} {
  
}
@media ${mediaQueries.smallDevices} {
  
}
@media ${mediaQueries.mediumDevices} {
 
}
@media ${mediaQueries.largeDevices} {
  
}
@media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
  
}
`;

const Line = styled.div`
width: 100%;
border-bottom: 1px solid grey;
margin-top: 80px;
@media ${mediaQueries.extraSmallDevices} {
  
}
@media ${mediaQueries.smallDevices} {
  
}
@media ${mediaQueries.mediumDevices} {
 
}
@media ${mediaQueries.largeDevices} {
  
}
@media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
  
}
`;


const Services = ()=>{
    const refs = useRef({});
    const [activeIndex, setActiveIndex] = useState(null); 
    
    const scrollToSection = (index) => {
        // Check if the ref exists before calling scrollIntoView
        if (refs.current[index]) {
            // refs.current[index].scrollIntoView({
            //     behavior: 'smooth', // smooth scroll
            //     block: 'end', // align to the top of the container
            //     inline: 'nearest' // ensures scrolling is minimal and avoids layout shifts
            // });
            window.scrollTo({
                top: refs.current[index].offsetTop - 90,
                behavior: 'smooth'
            });
        } else {
            console.error('Section ref is undefined!');
        }
    };
    
    // Função para monitorar o scroll e verificar qual seção está no topo da tela
    const handleScroll = () => {
        const elements = Object.values(refs.current); // Obter todas as seções
        let currentIndex = null;

        // Verificar qual seção está no topo da tela
        elements.forEach((el, index) => {
            const rect = el.getBoundingClientRect();
            if (rect.top >= 0 && rect.top <= window.innerHeight/3) {
                currentIndex = index;
            }
        });

        if (currentIndex !== null) {
            setActiveIndex(currentIndex); // Atualizar o índice da seção ativa
        }
    };

    useEffect(() => {
        // Adicionar o listener de scroll
        window.addEventListener('scroll', handleScroll);

        // Limpar o listener quando o componente for desmontado
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    return (
        <Page>
            <FadeInWrapper>
                <LargeDevice>
                  <Subheader>
                      
                      {data.map((item, index) => (
                          <SubheaderSectionButton
                              key={index}
                              ref={(el) => (refs.current[index] = el)} // Atribui a ref ao item
                              onClick={() => scrollToSection(index)} // Exemplo de manipulação da ref
                              isActive={activeIndex === index} // Adiciona uma classe ou estilo para destacar o botão ativo
                          >
                              {item.serviceName}
                          </SubheaderSectionButton>
                      ))}
                  
          
                  </Subheader>
                </LargeDevice>
                <Sectionsbox>
                
                    {data.map((item, index) => (
                            <Section
                                key={index}
                                ref={(el) => (refs.current[index] = el)} // Atribui a ref ao item
                            >
                                <SectionTitle>{item.serviceName}</SectionTitle>
                                <ImageWrapper><img width={"100%"} src={item.imageURL}></img></ImageWrapper>
                                <Description>{item.description}</Description>
                                <Line/>
                            </Section>
                    ))}
                
                </Sectionsbox>
            </FadeInWrapper>

        </Page>
      );
};

export default Services;
