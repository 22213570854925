import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import mediaQueries from "../constants/mediaQueries";
import breakpoints from "../constants/breakpoints";
import { fadeIn } from 'react-animations';
 
const fadeAnimation = keyframes`${fadeIn}`;

const DropdownTitle = styled.div`
  position: relative;
  padding: 10px;
  color: white;
  font-family: "DM Sans", sans-serif;
  &:hover {
    color: grey;
    text-decoration: none;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 12px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 16px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 17px;
  }
`;

const DropdownList = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: #00373e;
  padding: 10px;
  margin-top: 10px;
  z-index: 1001;

  animation: 0.3s ${fadeAnimation};
  @media ${mediaQueries.mediumDevices} {
    width: 150px;
  }
  @media ${mediaQueries.largeDevices} {
    width: 250px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 250px;
  }
`;

const DropdownListItem = styled.div`
  padding: 10px;
  background: #00373e;
  color: grey;
  font-family: "DM Sans", sans-serif;
  width: 100%;

  &:hover {
    color: white;
    text-decoration: none;
  }
  z-index: 1001;
  @media ${mediaQueries.mediumDevices} {
    font-size: 12px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 15px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 16px;
  }
`;

const DropdownHeader = ({ text, sections, link }) => {
  const [open, setOpen] = useState(false);
  if (link !== undefined) {
    return (
      <DropdownTitle as={Link} to={link}>
        {text}
      </DropdownTitle>
    );
  }
  const renderList = (array) => {
    return array.map(({ title, link }) => {
      return (
        <DropdownListItem as={Link} to={link} key={title}>
          {title}
        </DropdownListItem>
      );
    });
  };
  return (
    <DropdownTitle
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      {text}
      {open && <DropdownList>{renderList(sections)}</DropdownList>}
    </DropdownTitle>
  );
};

export default DropdownHeader;
