import React  from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import mediaQueries from "../constants/mediaQueries";
import breakpoints from "../constants/breakpoints";
import Logo from "./LogoBuril";
import DropdownHeader from "./DropdownHeader";
import NavBarData from "../assets/content/NavBarData";


const HeaderContainer = styled.div`
  z-index: 1000;
  position: fixed;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: stretch;
  background: #171718;
  user-select: none;
  cursor: pointer;
  @media ${mediaQueries.extraSmallDevices} {
    justify-content: center;
    
  }
  @media ${mediaQueries.smallDevices} {
    justify-content: flex-start;
  }
  @media ${mediaQueries.mediumDevices} {
    justify-content: flex-start;
    height: 60px;
  }
  @media ${mediaQueries.largeDevices} {
    justify-content: flex-start;
    height: 75px;
  }
@media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 100%;
    height: 85px;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  width: 75%;
  align-content: center;
  background: #171718;
  z-index: 1000;
`;

const Menu = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  color: white;
  @media ${mediaQueries.smallDevices} {
    justify-content: space-between;
  }
  @media ${mediaQueries.mediumDevices} {
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-around;
  }
  @media ${mediaQueries.largeDevices} {
    padding-left: 30px;
    padding-right: 30px;
    justify-content: space-around;
  }
  @media ${mediaQueries.extraLargeDevices} {
    justify-content: space-around;
  }
`;

const LogoModuleContainer = styled.div`
  background: #171718;
  &:hover {
    text-decoration: none;
  }
  @media ${mediaQueries.extraSmallDevices} {
    width: 300px;
  }
  @media ${mediaQueries.smallDevices} {
    width: 300px;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 200px;
  }
  @media ${mediaQueries.largeDevices} {
    width: 250px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 300px;
  }
  z-index: 1000;
`;

const LogoContainer = styled.div`
  margin: 10px;
  &:hover {
    text-decoration: none;
  }
`;

const Header = () => {
  const renderSections = (array) => {
    return array.map(({ title, sections, link }) => {
      if (link === undefined) {
        return <DropdownHeader text={title} sections={sections} key={title} />; // Normal Dropdown
      } else {
        return (
          <DropdownHeader
            text={title}
            sections={sections}
            link={link}
            key={title}
          />
        ); // Dumby Dropdown
      }
    });
  };
  
  return (
    <HeaderContainer>
      <LogoModuleContainer as={Link} to={"/"}>
        <LogoContainer>
          <Logo width={"100%"} height={"54%"} />
        </LogoContainer>
      </LogoModuleContainer>
      <MenuContainer>
        <Menu>{renderSections(NavBarData)}</Menu>
      </MenuContainer>
    </HeaderContainer>
  );
};

export default Header;
