import React from "react";
import styled from "styled-components";
import {
  LargeDevice,
  Mobile,
  SuperDesktop,
  NotSuperDesktop,
} from "../components/ReactResponsiveComponents";
import mediaQueries from "../constants/mediaQueries";
import breakpoints from "../constants/breakpoints";
import { Link } from "react-router-dom";

const BackContainer = styled.div`
  /* float: ${(props) => (props.direction === "left" ? `left` : `right`)}; */
  background: #171718;
  width: 100%;
  margin-top: 1px; // Margem entre InfoBlocks
  user-select: none;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  font-family: "Druk", sans-serif;
  align-items: center;
  justify-content: space-between;
  /* background: yellow; */
  /* @media ${mediaQueries.extraSmallDevices} {
    padding: 110px 20px 60px 20px;
  }
  @media ${mediaQueries.smallDevices} {
    padding: 110px 20px 60px 20px;
  }
  @media ${mediaQueries.mediumDevices} {
    padding: 100px 40px 60px 40px;
  }
  @media ${mediaQueries.largeDevices} {
    padding: 100px 40px 60px 40px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    padding: 100px 40px 60px 40px;
  } */
`;

const DetailsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media ${mediaQueries.extraSmallDevices} {
    flex-direction: column;
  }
  @media ${mediaQueries.smallDevices} {
    flex-direction: column;
  }
  @media ${mediaQueries.mediumDevices} {
    flex-direction: row;
  }
  @media ${mediaQueries.largeDevices} {
    flex-direction: row;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    flex-direction: row;
  }
`;

const TextContainer = styled.div`
  display: flex;
  padding:50px;
  @media ${mediaQueries.extraSmallDevices} {
    flex-direction: column;
  }
  @media ${mediaQueries.smallDevices} {
    flex-direction: column;
  }
  @media ${mediaQueries.mediumDevices} {
    flex-direction: column;
    width: 60%;
  }
  @media ${mediaQueries.largeDevices} {
    flex-direction: column;
    width: 30%;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    flex-direction: column;
    width: 40%;
  }
`;

const TextItem = styled.div`
  position: relative;
`;

const TDItem = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  /* @media ${mediaQueries.extraSmallDevices} {
    margin-right: 0px;
  }
  @media ${mediaQueries.smallDevices} {
    margin-right: 0px;
  }
  @media ${mediaQueries.mediumDevices} {
    margin-right: 20px;
    width: 38vw;
  }
  @media ${mediaQueries.largeDevices} {
    margin-right: 20px;
    width: 100%;
    max-width: 600px;
    text-align: "left";
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    margin-right: 30px;
    width: 35vw;
    text-align: ${(props) => (props.direction === "left" ? `left` : `right`)};
  } */
`;

const Title = styled.div`
  /* position: absolute; */
  width: 100%;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 18px;
    /* top: -30px; */
    /* transform: translate(0%, -100%); // move pivot point */
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 20px;
    /* top: -30px; */
    /* transform: translate(0%, -100%); // move pivot point */
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 20px;
    /* top: -30px; */
    /* transform: translate(0%, -100%); // move pivot point */
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 23px;
    /* top: -30px; */
    /* transform: translate(0%, -100%); // move pivot point */
    min-width: 500px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 28px;
    /* top: -30px; */
    /* transform: translate(0%, -100%); // move pivot point */
  }
`;

const Description = styled.div`
  font-family: "DM Sans", sans-serif;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 15px;
    margin-top: 40px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 17px;
    margin-top: 40px;
  }
  @media ${mediaQueries.mediumDevices} {
    font-size: 14px;
    margin-top: 50px;
  }
  @media ${mediaQueries.largeDevices} {
    font-size: 17px;
    margin-top: 50px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 20px;
    margin-top: 50px;
  }
`;

const ImagesItem = styled.div`
    width: 100%;
  @media ${mediaQueries.extraSmallDevices} {
    margin-top: 20px;
    margin-right: 0px;
  }
  @media ${mediaQueries.smallDevices} {
    margin-top: 20px;
    margin-right: 0px;
  }
  @media ${mediaQueries.mediumDevices} {
    margin: ${(props) =>
      props.direction === "left" ? `35px 35px 35px 35px` : `35px 35px 35px 35px`};
  }
  @media ${mediaQueries.largeDevices} {
    margin: ${(props) =>
      props.direction === "left" ? `35px 35px 35px 35px` : `35px 35px 35px 35px`};
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    margin: ${(props) =>
      props.direction === "left" ? `35px 35px 35px 35px` : `35px 35px 35px 35px`};
  }
`;

const ImageItem1 = styled.div`
  @media ${mediaQueries.extraSmallDevices} {
    width: 100%;
  }
  @media ${mediaQueries.smallDevices} {
    width: 100%;
  }
  @media ${mediaQueries.mediumDevices} {
    width: 100%;
  }
  @media ${mediaQueries.largeDevices} {
    width: 100%;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    width: 100%;
  }
`;

const ListContainer = styled.div`
  font-family: "DM Sans", sans-serif;
  font-weight: medium;
  @media ${mediaQueries.extraSmallDevices} {
    margin: 20px 0px 20px 0px;
  }
  @media ${mediaQueries.smallDevices} {
    margin: 20px 0px 20px 0px;
  }
  @media ${mediaQueries.mediumDevices} {
    margin: 40px 0px 20px 0px;
  }
  @media ${mediaQueries.largeDevices} {
    margin: 40px 0px 20px 0px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    margin: 40px 0px 20px 0px;
  }
`;

const List = styled.div`
  font-size: 14px;
  @media ${mediaQueries.largeDevices} {
    font-size: 14px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    font-size: 16px;
  }
`;

const ActionButton = styled.div`
  color: #eacab1;
  font-style: "DM Sans", "Druk", sans-serif;
  border-radius: 7px;
  text-decoration: underline;
  &:hover {
    color: #00373e;
    text-decoration: underline;
    cursor: pointer;
  }
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 12px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 14px;
  }
  @media ${mediaQueries.mediumDevices} {
    position: initial;
    align-self: ${(props) =>
      props.direction === "left" ? `flex-start` : `flex-end`};
    margin-top: 15px;
    margin-right: 20px;
    margin-left: 20px;
  }
  @media ${mediaQueries.largeDevices} {
    position: initial;
    align-self: ${(props) =>
      props.direction === "left" ? `flex-start` : `flex-end`};
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    font-size: 13px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    position: initial;
    align-self: ${(props) =>
      props.direction === "left" ? `flex-start` : `flex-end`};
    margin-top: 35px;
    margin-right: 0px;
    margin-left: 0px;
    font-size: 14px;
  }
`;

const RenderUnorderedList = (list) => {
  return (
    <ul style={{ listStylePosition: "inside" }}>
      {list.map((item) => (
        <li style={{ listStyleType: "circle" }}>{item}</li>
      ))}
    </ul>
  );
};

const InfoBlock = ({
  title,
  description,
  includes,
  imageURL,
  direction,
  button,
}) => {
  const RenderActionButton = (button) => {
    if (button !== undefined) {
      console.log(button);
      return (
        <ActionButton as={Link} to={button.link} direction={direction}>
          {button.name}
        </ActionButton>
      );
    }else{
      return <></>;
    }
  };
  const textElement = (
    <>
      <TextContainer>
        <TextItem>
          <TDItem direction={direction}>
            <Title>{title}</Title>
            <Description>{description}</Description>
            <SuperDesktop>{RenderActionButton(button)}</SuperDesktop>
          </TDItem>
        </TextItem>
        <TextItem>
          <ListContainer>
            <List>{RenderUnorderedList(includes)}</List>
          </ListContainer>
        </TextItem>

        <NotSuperDesktop>{RenderActionButton(button)}</NotSuperDesktop>
      </TextContainer>
    </>
  );
  const imageElement = (
    <>
      <ImagesItem direction={direction}>
        <ImageItem1 direction={direction}>
          <img width={"100%"} src={imageURL}></img>
        </ImageItem1>
      </ImagesItem>
    </>
  );

  if (direction === "left") {
    return (
      <BackContainer direction={direction}>
        <InfoContainer direction={direction}>
          <DetailsContainer direction={direction}>
            {textElement}
            {imageElement}
          </DetailsContainer>
        </InfoContainer>
      </BackContainer>
    );
  } else {
    return (
      <BackContainer direction={direction}>
        <InfoContainer direction={direction}>
          <DetailsContainer direction={direction}>
            <LargeDevice>
              {imageElement}
              {textElement}
            </LargeDevice>
            <Mobile>
              {textElement}
              {imageElement}
            </Mobile>
          </DetailsContainer>
        </InfoContainer>
      </BackContainer>
    );
  }
};

export default InfoBlock;
