import React from "react";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import ProjectThumbnail from "./ProjectThumbnail";
import mediaQueries from "../constants/mediaQueries";
import breakpoints from "../constants/breakpoints";
import baseUrl from "../constants/baseUrl";

const url = baseUrl.projects;

const FadeInWrapper = styled.div`
  opacity: 0;
  animation: fadeIn 2s forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const slideDown = keyframes`
  0% {
    transform: translateY(-50px); /* Começa acima do normal */
  }
  100% {
    transform: translateY(0); /* Vai para a posição original */
  }
`;

const Block = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  color: white;
  align-items: flex-start;
  font-family: Druk, sans-serif;
  background: rgb(241, 240, 236);
  @media ${mediaQueries.extraSmallDevices} {
    padding: 30px 25px;

  }
  @media ${mediaQueries.smallDevices} {
    padding: 40px 25px;

  }
  @media ${mediaQueries.mediumDevices} {
    padding: 20px 50px 100px 50px;

  }
  @media ${mediaQueries.largeDevices} {
    padding: 25px 50px 100px 50px;

  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    padding: 30px 50px 100px 50px;
  }
`;

const BlockTitle = styled.div`
  font-size: 25px;
  /* border-bottom: 2px black solid; */
  color: #171718;
  @media ${mediaQueries.extraSmallDevices} {
    font-size: 14px;
  }
  @media ${mediaQueries.smallDevices} {
    font-size: 16px;
  }
  @media ${mediaQueries.mediumDevices} {
    margin: 0px 20px;
    font-size: 15px;
  }
  @media ${mediaQueries.largeDevices} {
    margin: 0px 25px;
    font-size: 16px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    margin: 0px 40px;
    font-size: 17px;
  }
`;



const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* background: pink; */
  @media ${mediaQueries.extraSmallDevices} {
    margin-top: 10px;
  }
  @media ${mediaQueries.smallDevices} {
    margin-top: 15px;
  }
  @media ${mediaQueries.mediumDevices} {
    margin-top: 20px;
  }
  @media ${mediaQueries.largeDevices} {
    margin-top: 20px;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    margin-top: 20px;
  }
`;

const ListItem = styled.div`
  @media ${mediaQueries.extraSmallDevices} {
    flex: 0 0 100%;
  }
  @media ${mediaQueries.smallDevices} {
    flex: 0 0 100%;
  }
  @media ${mediaQueries.mediumDevices} {
    flex: 0 0 33.333333%;
  }
  @media ${mediaQueries.largeDevices} {
    flex: 0 0 33.333333%;
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
    flex: 0 0 33.333333%;
  }
`;

const SubBlock = styled.div`
  display: flex;
  z-index: 100;
  width: 100%;
  color: white;
  border-bottom: 1px #00373E solid;
  padding-bottom: 5px;
  justify-content: space-between;
  /* Aplica a animação */
  animation: ${slideDown} 500ms ease-out;
  @media ${mediaQueries.extraSmallDevices} {
  }
  @media ${mediaQueries.smallDevices} {
  }
  @media ${mediaQueries.mediumDevices} {
  }
  @media ${mediaQueries.largeDevices} {
  }
  @media screen and (min-width: ${breakpoints.extraLargeDevices}px) {
  }
`;


class WorksPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, projects: [] };
  }


  componentDidMount() {
    axios.get(`/projects`).then((res) => {
      let uploadedProjects = res.data;
      uploadedProjects = uploadedProjects.filter( (item) => item.enable===true);
      this.setState({ isLoading: false, projects: uploadedProjects });
    });
  }
  // process.env.PUBLIC_URL + "./images/project_database/" 
  render() {
    console.log(process.env.NODE_ENV);
    return (
 
        <FadeInWrapper>
            <Block>
                <SubBlock>
                  <BlockTitle>Com quem trabalhamos</BlockTitle>
                  <BlockTitle>@BW</BlockTitle>
                </SubBlock>
                <ListContainer>
                  {this.state.projects.map((item) => (
                    <ListItem>
                      <ProjectThumbnail
                        title={item.title}
                        subtitle={item.logline}
                        imageSrc={url + item.thumbnailWideUrl}
                        link={`/projetos/${item.title.replace(/\s/g, '_')}`}
                      />
                    </ListItem>
                  ))}
                </ListContainer>
            </Block>
          </FadeInWrapper>
   
    );
  }
}

export default WorksPage;
